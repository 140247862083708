* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #dfe5e5;
}

.root {
  padding: 8px 24px;
  height: 100%;
  width: 100%;
  font-weight: normal;
  font-smoothing: antialiased;
}

.app-header {
  padding: 20px;
}

.logo {
  padding-left: 0;
}

.login {
  width: 420px;
  margin: 0 auto;
  background-color: #f5f5f6;
  border: 2px solid #e3e3e3;
  border-radius: 4px;
}

.unauthorized-user-dialog {
  width: 350px;
  margin-left: 30px;
  font-size: 12px;
  text-align: center;
}

.button {
  padding: 0.75rem 1.25rem;
}

.google-button {
  width: 100%;
  padding: 0.75rem 1.25rem;
  line-height: 24px;
  text-decoration: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  transition: 0.1s background-color ease;
  color: #fff;
  background: #0f9d58;
  border-color: #0f9d58;
}
.google-button:hover {
  background: #0b6e3e;
  border-color: #0b6e3e;
}

.btn-logout {
  color: #000;
}

.btn-logout:hover,
.btn-logout:active,
.btn-logout:focus {
  color: #000;
  text-decoration: none;
}

.navbar-username {
  color: #000;
}

th.header {
  color: #666666;
  text-transform: uppercase;
  font-weight: 100;
}
